import React from "react";
import "../styles/LoginPage.css";

export default function LoginPage(props) {
  const [loginData, setLoginData] = React.useState({
    login: "",
    password: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;

    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  async function handleClick() {
    try {
      const response = await fetch(`http://localhost:65002/User?login=${loginData.login}`);
    
      console.log("Response:", response);
    
      if (response.ok) {
        const userData = await response.json();
    
        console.log("User Data:", userData);
    
        if (userData && userData.pass === loginData.password) {
          console.log("Login successful");
          props.navigateAddOrder();
        } else {
          console.log("Invalid login credentials");
        }
      } else {
        console.log("Error fetching user data:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }  

  return (
    <div className="LoginPage">
      <form className="login-form">
        <label htmlFor="login">Login:</label>
        <input
          type="text"
          id="login"
          name="login"
          value={loginData.login}
          onChange={handleChange}
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={loginData.password}
          onChange={handleChange}
        />

        <button type="button" onClick={handleClick}>
          Submit
        </button>
      </form>
    </div>
  );
}
