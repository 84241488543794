import React, { useEffect, useRef } from "react";
import Header from "./Header";
import Chart from 'chart.js/auto'; 
import "../styles/OrderPage.css";

export default function OrdersPage(props) {
  const chartRef = useRef();

  useEffect(() => {
    if (props.optData) {

      renderHistogram(props.optData);
    }
  }, [props.csvFile, props.bookCount]);

  const renderHistogram = (printingResults) => {
    const data = {
      labels: printingResults.map(book => book["Book name"]),
      datasets: [
        {
          label: "Quantity",
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.4)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: props.optData.map(book => book["Printed"]),
        },
      ],
    };

    const options = {
      scales: {
        x: { title: { display: true, text: "Books" } },
        y: { title: { display: true, text: "Quantity" } },
      },
    };

    const ctx = chartRef.current.getContext("2d");
    new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
    });
  };

  return (
    <div className="OrdersPage">
      <Header
        className="Header"
        navigateProfit={props.navigateProfit}
        navigateOrder={props.navigateOrder}
        navigateAddOrder={props.navigateAddOrder}
      />
      <div className="OrdersGrid">
        {props.optData && (
          <div>
            <h3>Results:</h3>
            <canvas ref={chartRef} width="400" height="200"></canvas>
          </div>
        )}
      </div>
    </div>
  );
}
