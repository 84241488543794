import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import Header from "./Header";
import "../styles/AddOrderPage.css";


export default function AddOrderPage(props) {
  const [customer, setCustomer] = useState("");
  const [bookCount, setBookCount] = useState("");
  const [csvFile, setCsvFile] = useState(null);

  const handleCustomerChange = (e) => {
    setCustomer(e.target.value);
  };

  const handleBookCountChange = (e) => {
    setBookCount(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File is fine");
      setCsvFile(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!csvFile) {
      alert("Please select a CSV file.");
      return;
    }

    Papa.parse(csvFile, {
      complete: async (result) => {
        const csvData = result.data;
        console.log(csvData);

        const requestData = {
          bookCount: bookCount,
          data: csvData,
        };

        try {
          const response = await axios.post("http://localhost:65002/optimize", requestData);
          const optimizationResults = response.data.results;
          console.log("Optimization Results:", optimizationResults);

          props.navigateOrder();
        } catch (error) {
          console.error("API Request Error:", error);
          alert("Optimization request failed. Please try again.");
        }
      },
      header: true,
    });
  };
  
  return (
    <div className="AddOrderPage">
      <Header
        className="Header"
        navigateProfit={props.navigateProfit}
        navigateOrder={props.navigateOrder}
        navigateAddOrder={props.navigateAddOrder}
      />
      <div className="wrapper">
        <form onSubmit={handleSubmit} className="UploadForm">
          <label htmlFor="customer" className="label">
            Pasūtītājs:
          </label>
          <input
            type="text"
            id="customer"
            name="customer"
            value={customer}
            onChange={handleCustomerChange}
          />

          <label htmlFor="bookCount" className="label">
            Grāmatu skaits:
          </label>
          <input
            type="text"
            id="bookCount"
            name="bookCount"
            value={bookCount}
            onChange={handleBookCountChange}
          />

          <label htmlFor="csvFile" className="label">
            Grāmatas (CSV File):
          </label>
          <div className="custom-file-upload">
            <input
              type="file"
              id="csvFile"
              name="csvFile"
              accept=".csv"
              onChange={handleFileChange}
            />
            <span>Choose File</span>
          </div>


          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}
