import React from "react";
import "../styles/Header.css"; 

export default function Header(props) {
  return (
    <div className="Header">
      <h1 className="HeaderTitle">PrintLess</h1>
      <div className="NavigationButtons">
        <button onClick={props.navigateProfit}>Devices</button>
        <button onClick={props.navigateOrder}>Orders</button>
        <button onClick={props.navigateAddOrder}>Add Order</button>
      </div>
    </div>
  );
}
