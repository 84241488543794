import React, { useEffect, useRef } from "react";
import Header from "./Header";
import Chart from 'chart.js/auto';
import "../styles/ProfitPage.css";

export default function ProfitPage(props) {
  const chartRef = useRef();

  useEffect(() => {
    if (props.optData) {
      renderProfitChart();
    }
  }, [props.optData]);

  const renderProfitChart = () => {
    const accumulatedData = props.optData.map((book, index) => {
      const accumulatedProfit = props.optData.slice(0, index + 1)
        .reduce((sum, b) => sum + b["Profit"], 0);
  
      const accumulatedTime = props.optData.slice(0, index + 1)
        .reduce((sum, b) => sum + b["Time"], 0);
  
      return {
        x: accumulatedTime,
        y: accumulatedProfit,
      };
    });
  
    const data = {
      labels: accumulatedData.map(point => point.x),
      datasets: [
        {
          label: "Accumulated Profit",
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.4)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: accumulatedData.map(point => point.y),
        },
      ],
    };
  
    const options = {
      scales: {
        x: { title: { display: true, text: "Accumulated Time" } },
        y: { title: { display: true, text: "Accumulated Profit" } },
      },
    };
  
    const ctx = chartRef.current.getContext("2d");
    new Chart(ctx, {
      type: "line",
      data: data,
      options: options,
    });
  };  

  return (
    <div className="ProfitPage">
      <Header
        className="Header"
        navigateProfit={props.navigateProfit}
        navigateOrder={props.navigateOrder}
        navigateAddOrder={props.navigateAddOrder}
      />
      <div className="ProfitGrid">
        {props.optData && (
          <div>
            <h3>Profit Chart:</h3>
            <canvas ref={chartRef} width="500" height="200"></canvas>
          </div>
        )}
      </div>
    </div>
  );
}
