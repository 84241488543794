import React, { useState } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import AddOrderPage from "./components/AddOrderPage";
import ProfitPage from "./components/ProfitPage";
import OrderPage from "./components/OrderPage";
import LoginPage from "./components/LoginPage";

function App() {

  const rawData = [
      {
          "Book name": "Zvaigžņu putekļi",
          "Paper quality": "standart",
          "Cover type": "soft",
          "Page number": "100",
          "Colour": "Colorful",
          "Potential buyers": "500",
          "Price": "25.99"
      },
      {
          "Book name": "Melnās rozes",
          "Paper quality": "premium",
          "Cover type": "hard",
          "Page number": "200",
          "Colour": "Black and white",
          "Potential buyers": "1500",
          "Price": "35.99"
      },
      {
          "Book name": "Saules meita",
          "Paper quality": "standart",
          "Cover type": "hard",
          "Page number": "150",
          "Colour": "Colorful",
          "Potential buyers": "700",
          "Price": "28.99"
      },
      {
          "Book name": "Ziemeļu gaismas",
          "Paper quality": "premium",
          "Cover type": "soft",
          "Page number": "250",
          "Colour": "Black and white",
          "Potential buyers": "1200",
          "Price": "38.99"
      },
      {
          "Book name": "Vēja ēnas",
          "Paper quality": "standart",
          "Cover type": "hard",
          "Page number": "175",
          "Colour": "Colorful",
          "Potential buyers": "800",
          "Price": "30.99"
      },
      {
          "Book name": "Zaļās pļavas",
          "Paper quality": "premium",
          "Cover type": "soft",
          "Page number": "300",
          "Colour": "Black and white",
          "Potential buyers": "1000",
          "Price": "40.99"
      },
      {
          "Book name": "Jūras dziesma",
          "Paper quality": "standart",
          "Cover type": "hard",
          "Page number": "125",
          "Colour": "Colorful",
          "Potential buyers": "600",
          "Price": "27.99"
      },
      {
          "Book name": "Kalnu klusums",
          "Paper quality": "premium",
          "Cover type": "soft",
          "Page number": "275",
          "Colour": "Colorful",
          "Potential buyers": "1300",
          "Price": "39.99"
      },
      {
          "Book name": "Upes stāsts",
          "Paper quality": "standart",
          "Cover type": "hard",
          "Page number": "225",
          "Colour": "Colorful",
          "Potential buyers": "900",
          "Price": "33.99"
      },
      {
          "Book name": "Meža māte",
          "Paper quality": "premium",
          "Cover type": "soft",
          "Page number": "35",
          "Colour": "Black and white",
          "Potential buyers": "1100",
          "Price": "42.99"
      }
  ]

  const optData =[
    {
      'Book name': 'Zvaigžņu putekļi',
      Time: 1.6,
      Profit: 121.95,
      Printed: 5
    },
    {
      'Book name': 'Melnās rozes',
      Time: 2.2,
      Profit: 161.95,
      Printed: 5
    },
    {
      'Book name': 'Saules meita',
      Time: 1.9,
      Profit: 126.95,
      Printed: 5
    },
    {
      'Book name': 'Ziemeļu gaismas',
      Time: 2.5,
      Profit: 186.95,
      Printed: 5
    },
    { 'Book name': 'Vēja ēnas', Time: 2.05, Profit: 136.95, Printed: 5 },
    {
      'Book name': 'Zaļās pļavas',
      Time: 2.8,
      Profit: 196.95,
      Printed: 5
    },
    {
      'Book name': 'Jūras dziesma',
      Time: 1.75,
      Profit: 121.95,
      Printed: 5
    },
    {
      'Book name': 'Kalnu klusums',
      Time: 2.65,
      Profit: 191.95,
      Printed: 5
    },
    {
      'Book name': 'Upes stāsts',
      Time: 2.35,
      Profit: 151.95,
      Printed: 5
    },
    {
      'Book name': 'Meža māte',
      Time: 1.21,
      Profit: 2359.23,
      Printed: 57
    }
  ]

  const [optRez, setOptRez] = useState([]);

  const navigate = useNavigate();

  function navigateProfit() {
    console.log("Navigate to Profit");
    navigate("/Profit");
  }

  function navigateOrder() {
    console.log("Navigate to Order");
    navigate("/Order");
  }

  function navigateAddOrder() {
    console.log("Navigate to Add Order");
    navigate("/AddOrder");
  }

  const element = useRoutes([
    {
      path: "/",
      element: <LoginPage navigateAddOrder={navigateAddOrder} />,
    },
    {
      path: "/Profit",
      element: (
        <ProfitPage
          navigateProfit={navigateProfit}
          navigateOrder={navigateOrder}
          navigateAddOrder={navigateAddOrder}
          optData={optData}
        />
      ),
    },
    {
      path: "/Order",
      element: (
        <OrderPage
          navigateProfit={navigateProfit}
          navigateOrder={navigateOrder}
          navigateAddOrder={navigateAddOrder}
          optData={optData}
        />
      ),
    },
    {
      path: "/AddOrder",
      element: (
        <AddOrderPage
          navigateProfit={navigateProfit}
          navigateOrder={navigateOrder}
          navigateAddOrder={navigateAddOrder}
          setOptRez={setOptRez}
        />
      ),
    },
  ]);

  return element;
}

export default App;